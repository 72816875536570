import * as React from "react"
import { Layout, SEO } from "../components"
import { useWindowSize } from "../utils"
import errorImage from "./../images/404.jpg"

const NotFoundPage: React.FC = () => {
  const { height } = useWindowSize()

  return (
    <Layout title="404: Not found">
      <SEO title="404: Not found" />
      <div
        style={{
          position: "relative",
          backgroundImage: `url("${errorImage}")`,
          height: height - 300,
          width: "100%",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            backgroundImage:
              "linear-gradient(90deg, rgba(45, 45, 58, 0.4) 15%, rgba(45, 45, 58, 0.6) 50%, rgba(43, 43, 53, 0.4) 100%)",
            width: "100%",
            boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.2)",
            height: "100%",
          }}
        >
          <div style={{ position: "absolute", top: "20%", left: "10%" }}>
            <h1 className="styled-font">404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
